// import { useTranslation } from 'react-i18next';
// import { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { passwordSuggestion } from '../_helpers/Utils';


// Looping thorugh all ptero egg variables
// and creates a input field for every one
// with their argument name as the inputs name
const EggLoader = ({ className, gameTypes }) => {
    // const { t } = useTranslation();

    const changeInputValue = (e, index) => {
      // Changing tmp field current_value,
      // so when the user submits the form
      // these values will follow creating
      // the server.
      const eggVarIndex = index;
      const userInput   = e.target.value;
    };

      return (
        <>
          <ul>
            { 
              gameTypes.length !== 0 && 
              gameTypes.map((eggValue, index) => {
                // Show if users are allowed to view AND edit
                if(eggValue.user_viewable === true && eggValue.user_editable === true) {
                  let passSugg = '';

                    // If egg field is filled with this text
                    // it random generates a suggestion password
                    // for the user. So if they don't choose any
                    // it will defaults to that password so that
                    // we increase security.
                    if(eggValue.default_value === 'CHANGEME') {
                      passSugg = passwordSuggestion(10, false);
                      gameTypes[index].current_value = passSugg;
                    } else {
                      passSugg = eggValue.default_value;
                    }

                    return (
                      <li key={`key-${index}`} className="mb-2">
                        <input type="text"
                          placeholder={passSugg}
                          onChange={(event) => changeInputValue(event, index)}
                          id={`field-${eggValue.env_variable}`}
                          className="form-control"
                          name={eggValue.env_variable}
                        />
                        <label className="form-label" htmlFor={`field-${eggValue.env_variable}`}>{eggValue.name}</label> 
                        <div className="text-white tooltip">
                          <Icon.QuestionCircleFill />
                            <div className="right danger">
                              {eggValue.description}
                            </div>
                        </div>
                      </li>
                    )
                } else {
                  return false;
                }
              })
            }
          </ul>
        </>
      );
};

export default EggLoader;