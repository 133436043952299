import { useEffect, useState } from 'react';
import { useLoadingContext } from 'react-router-loading';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';

import axios, { axiosPrivate } from '../_api/axios';
import Header from '../_components/Header';
import Footer from '../_components/Footer';
import { useTranslation } from 'react-i18next';

import useAuth from '../_hooks/useAuth';

import Select from 'react-select';
import * as Icon from 'react-bootstrap-icons';
import SelectStyle from '../_helpers/SelectStyle';

import ErrorHandler from '../_helpers/ErrorHandler';

import EggLoader from '../_helpers/EggLoader';

import { ProductWrapper, StockColor } from '../_helpers/ProductWrapper';

import {
  MDBCol,
  MDBRow,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
} from 'mdb-react-ui-kit';

const BUY_URL = '/buy/server';
const BOUGHT_URL = '/profile';

const GameServers = () => {

  const loadingContext = useLoadingContext();
  const { auth } = useAuth();

  // Temporary server name if none is selected
  const tmpServerName = 'Ghost Gaming Server';

  // Check if logged in
  const hasAuth = auth?.roles && auth?.accessToken;

  const params = useParams();
  const { t } = useTranslation();

  const [subscription, setSubscription] = useState(false);

  const [fetchError, setFetchError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedData, setLoadedData] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [badgeClass, setBadgeClass] = useState('green');

  const [credit, setCredit] = useState(0);
  const [stock, setStock] = useState(0);
  const [canBuy, setCanBuy] = useState(false);

  const [discount, setDicsount] = useState(0);

  const [products, setProducts] = useState([]);

  const [hasAgreed, setHasAgreed] = useState(false);

  const [btnLoading, setBtnLoading] = useState(false);

  const [choosenServer, setChoosenServer] = useState({});
  const [choosenServerPackage, setChoosenServerPackage] = useState({});
  const [choosenServerName, setChoosenServerName] = useState(tmpServerName);
  const [gameType, setGameType] = useState(1);
  const [gameTypes, setGameTypes] = useState([]);
  const [gameTypeData, setGameTypeData] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const handleBuySubmit = async (e) => {
    e.preventDefault();
    setErrMsg('');

    setBtnLoading(true);

    if(!hasAgreed) {
      setErrMsg(t("AcceptOurAgreement"));
      setBtnLoading(true);
      return;
    }

    if(choosenServerName === '') {
      setChoosenServerName(tmpServerName);
    }

    const packageID = choosenServer._id;

      try {
        const response = await axiosPrivate.post(BUY_URL,
          JSON.stringify({ packageID, choosenServerPackage, choosenServerName, gameType, subscription }),
          {
            headers: {
              'Content-type': 'application/json',
              'Authorization': `Bearer ${auth?.accessToken}`,
            },
            withCredentials: true
          }
        );

        if (response?.status === 200) {
          navigate(BOUGHT_URL, { replace: true });
        }

        if (response?.status === 410) {
          setErrMsg(t("ThisProductIsSoldOut"));
        }

        setBtnLoading(true);

      } catch(err) {
        if (err.response?.status === 409 ||
            err.response?.status === 403 ||
            err.response?.status === 401) {
          setErrMsg(err);
        } else if(err.response?.status === 422) {
          setErrMsg(t("ThereWasAnError"));
        } else {
          setErrMsg(t("PaymentFailed"));
        }
        
        setBtnLoading(false);
      }
  }

  const toggleSubscription = (e) => {
    setSubscription(e.target.checked);
  }

  const toggleAgreed = (e) => {
    setHasAgreed(e.target.checked);
  }

  // const onServerNameChange = (e) => {
  //   setChoosenServerName(e.target.value);
  // }

  const onGameTypeChange = (e) => {
    setGameTypeData(e.data);
    setGameType(e.value);
  }

  useEffect(() => {

    let isMounted = true;
      const getProduct = async (url) => {
        setIsLoading(true);
        setErrMsg('');

          try {
            const response = await axios.post(url, {
               headers: {
                  'Content-type': 'application/json',
               }
            });

              if (isMounted) {
                  setProducts(response.data);
                  setFetchError(null);
                  setCredit(auth?.credit);
              }

              loadingContext.done();
          } catch(err) {
              if (isMounted) {
                  setFetchError(err.message);
                  setProducts([]);
              }
          } finally {
              setIsLoading(false);
              isMounted = false;
          }
      }

      getProduct(`${process.env.REACT_APP_BACKEND_URL}/product/` + params.serverName);

      const cleanUp = () => {
          isMounted = false;
      }

        return cleanUp;
  }, [fetchError, auth, navigate, params, loadingContext]);

  useEffect(() => {

    if(Object.keys(products).length !== 0 && !isLoading) {

      setChoosenServer(products);

        if(products.gametypes) {
          setGameType(products.gametypes[0].egg);
        } else {
          setErrMsg(t("InvalidEntry"));
        }

        setGameTypeData(products.gametypes[0].options);

        let tmpGameTypes = [];

          products.gametypes.map((typeValue, typeIndex) => {
            return tmpGameTypes.push({ value: typeValue.egg, label: typeValue.name, data: typeValue.options });
          });

        setGameTypes(tmpGameTypes);

        products.packages.map((packValue, packIndex) => {
          if(/[^/]*$/.exec(packValue.link)[0] === params.package) {
            
            setChoosenServerPackage(packValue);
            setStock(packValue.stock);
            setLoadedData(true);

            setDicsount(choosenServerPackage.price - ((choosenServerPackage.discount / 100) * choosenServerPackage.price));

              setBadgeClass(StockColor(packValue.stock));
          }

              return true;
        });

      if(credit - (choosenServerPackage.price - choosenServerPackage.discount) < 0) {
        setCanBuy(false);
      } else {
        setCanBuy(true);
      }

    }

  }, [setCanBuy, credit, choosenServerPackage, params, products, choosenServer, isLoading, t]);

  return (
    <>
      <Header />

        <div id="header_static" className={`${choosenServer.headerclass} container-fluid product single top-bg`}>
          <div className="product_top d-none d-lg-block">
            <h1 className={`card-header ${choosenServer.iconclass}`}>
              { choosenServerPackage.titlelang !== undefined ? choosenServer.title : '' }
            </h1>
          </div>

          <div className="container small pull-down">
            <div className="row">
              <div className="product">

                <MDBRow>
                  <MDBCol>
                    <MDBBreadcrumb className="content-nav bg-dark p-3 mb-0">
                      <MDBBreadcrumbItem>
                        <a href='/'>{t("Home")}</a>
                      </MDBBreadcrumbItem>
                      <MDBBreadcrumbItem>
                        <a href="/overview">{t("GameServers")}</a>
                      </MDBBreadcrumbItem>
                      <MDBBreadcrumbItem>
                        <a href={choosenServer.siteurl}>{choosenServer.title ? choosenServer.title : '?'}</a>
                      </MDBBreadcrumbItem>
                      <MDBBreadcrumbItem active>{choosenServerPackage.titlelang ? t(choosenServerPackage.titlelang) : '?'}</MDBBreadcrumbItem>
                    </MDBBreadcrumb>
                  </MDBCol>
                </MDBRow>

                <MDBRow className="product-cards">
                  <MDBCol xl={5} lg={5} sm={12} xs={12}>
                    {
                      loadedData ? (
                        <ProductWrapper 
                          value={choosenServerPackage}
                          noAnim={true}
                          noBtn={true}
                          noPrice={true}
                          video={choosenServer.video}
                          key={choosenServer._id}
                          className="sticky-top sticky-offset mb-4"
                          id="scrollspy"
                        />
                      ) : (
                        <div className="card card-body product d-flex">
                         <h3 className="card-header price">
                           {t("DoesNotExist")}
                         </h3>
                         <p>{t("DoesNotExistMsg")}</p>
                        </div>
                      )
                    }
                  </MDBCol>
                  <MDBCol xl={7} lg={7} sm={12} xs={12} className="mt-4 mb-4">
                  <ErrorHandler error={errMsg} />
                  { 
                    hasAuth && stock !== 0 ? (
                      <>
                        <div 
                          className="card card-body product d-flex"
                          data-mdb-spy="scroll"
                          data-mdb-target="#scrollspy"
                          data-mdb-offset="0"
                        >
                          {
                            choosenServerPackage.discount !== 0 ? (
                              <div className="ribbon-wrapper">
                                <div className="ribbon">{choosenServerPackage.discount}% {t("DiscountMsg")}</div>
                              </div>
                            ) : (
                              ''
                            )
                          }
                          <div className="header-line">
                            <h5 className="card-header p-2">
                              {t("Invoice")}
                            </h5>
                          </div>
                          <div className="container">
                            <p className="mb-2 fs-6">{t("Credit")} - {t('CurrencyFormat', { currency: credit.toFixed(2) })}</p>
                              <hr className="lg"/>
                            { 
                              choosenServerPackage.discount !== 0 ? (
                                <>
                                  <div className="col-12 text-right">
                                    <p className="m-3 fs-6">{t("FirstMonth")} - {t('CurrencyFormat', { currency: discount.toFixed(2) })}</p>
                                  </div>
                                  <hr/>
                                </>
                              ) : (
                                ''
                              )
                            }
                            <div className="col-12 text-right">
                              {
                                choosenServerPackage.discount !== 0 ? (
                                  <p className="m-3 fs-6"><s>1 {t("AMonth")} - {t('CurrencyFormat', { currency: choosenServerPackage.price.toFixed(2) })}</s></p>
                                ) : (
                                  <p className="m-3 fs-6">1 {t("AMonth")} - {t('CurrencyFormat', { currency: choosenServerPackage.price.toFixed(2) })}</p>
                                )
                              }
                            </div>
                              <hr/>
                            <div className="col-12 text-right">
                              <p className="m-3 fs-6">{t("Tax")} - {t('CurrencyFormat', { currency: ((choosenServerPackage.discount !== 0 ? discount : choosenServerPackage.price) * 1.25 - (choosenServerPackage.discount !== 0 ? discount : choosenServerPackage.price)).toFixed(2) })}</p>
                            </div>
                              <hr/>
                            <div className="col-12 text-right">
                              <p className="m-3 fs-6">{t("Total")} - <span className="total fw-bold">{t('CurrencyFormat', { currency: (choosenServerPackage.discount !== 0 ? discount : choosenServerPackage.price).toFixed(2) })}</span></p>
                            </div>
                              <hr className="lg mb-2"/>
                              { 
                                canBuy ? (
                                  <>
                                    <div className="col-12">
                                      <p className="fs-6">{t("CreditLeft")} - {t('CurrencyFormat', { currency: (credit - (choosenServerPackage.discount !== 0 ? discount : choosenServerPackage.price)).toFixed(2) })}</p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="col-12">
                                      <p className="fs-6">{t("ChargeAccount")}, <a href="/charge">{t("ChargeHere").toLowerCase()}</a></p>
                                    </div>
                                  </>
                                )
                              }
                          </div>

                          <div className="d-inline-block align-items-center mt-1">
                            {/*<input
                              type="text"
                              id="choosename"
                              value={choosenServerName}
                              onChange={onServerNameChange}
                              className="form-control"
                              placeholder={t("ChooseAServerName")}
                            />
                            <label className="form-label" htmlFor="choosename">
                              {t("ServerName")}
                            </label>*/}

                            {
                              // Load game types if any
                              choosenServer.gametypes &&
                              choosenServer.gametypes.length > 1 ? (
                                <>
                                  <div className="header-line">
                                    <h5 className="card-header p-2">
                                      {t("ChooseServerType")}
                                    </h5>
                                  </div>
                                  <Select
                                    options={gameTypes}
                                    styles={SelectStyle}
                                    id="choosetmp"
                                    placeholder={choosenServer.gametypes[0].name}
                                    className="countryChooser"
                                    onChange={onGameTypeChange}
                                  />
                                  <label className="form-label" htmlFor="choosetmp">
                                    {t("GameType")}
                                  </label>
                                </>
                                ) : ('')
                            }

                          <div className="header-line">
                            <h5 className="card-header p-2">
                              {t("ServerSettings")}
                            </h5>
                          </div>

                            <p className="alert alert-default d-flex justify-content-center align-items-center mt-3">
                              <i><Icon.ExclamationCircleFill className="me-3 fs-4 text-warning"/></i>
                              Denne opsætning kan altid ændres når serveren er købt og klar til brug!
                            </p>

                            <EggLoader className="" gameTypes={gameTypeData} />

                            <p className="alert alert-default d-flex justify-content-between align-items-center mt-3">
                              <i><Icon.QuestionSquare className="ms-2 me-3 fs-1 text-warning"/></i>
                              Har du udfordringer med køb eller opsætning af din server ?<br/> Så kan du finde hjælp på vores discord her.
                                <a href="https://discord.gg/CzdF799GRn" className="btn btn-purple ms-4 hopUp">
                                  <Icon.Discord className="fs-5 text-white"/>
                                </a>
                            </p>

                            { 
                              loadedData ? (
                                <>
                                  <MDBCol xl={12} lg={12} sm={12} xs={12} className="text-center">
                                    <hr className="mb-3" />
                                    <input
                                      className="form-check-input me-3 mt-1"
                                      type="checkbox"
                                      onChange={toggleSubscription}
                                      id="subscriptionCheck"
                                    />

                                    <label className="form-check-label mb-2" htmlFor="subscriptionCheck">
                                      {t("MonthlySubscription")}
                                    </label>
                                    
                                    <p>{t("DeployedIn")} <i className="text-success">{choosenServerPackage ? choosenServerPackage.deployment : 0}s</i></p>
                                     <hr className="mb-3" />
                                    <input
                                      className="form-check-input me-3 mt-3"
                                      type="checkbox"
                                      onChange={toggleAgreed}
                                      id="registerCheck"
                                      aria-describedby="registerCheckHelpText"
                                    />

                                    <label className="form-check-label mb-2" htmlFor="registerCheck">
                                      {t("AgreeTermsStart")} <a href="/terms-of-service">{t("TermsOfService").toLowerCase()}.</a><br /> {t("AgreeTermsEnd")} <a href="/privacy-policy">{t("PrivacyPolicy").toLowerCase()}.</a>
                                    </label>

                                    <button
                                      type="button"
                                      disabled={!canBuy || !loadedData || !hasAgreed || stock === 0 ? true : false || btnLoading}
                                      className="btn btn-success w-100"
                                      onClick={handleBuySubmit}
                                    >
                                      <Icon.Check className="fs-4 me-2" />{t("BuyServer")}
                                    </button>
                                  </MDBCol>
                                </>
                              ) : (
                                <p className="red">{t("ThisIsNotAnOption")}!</p>
                              )
                            }
                          </div>
                        </div>
                      </>
                      // Not logged in 
                  ) : (
                    <>
                      <div className="card card-body product d-flex">
                        {
                          choosenServerPackage.discount !== 0 ? (
                            <div className="ribbon-wrapper">
                              <div className="ribbon">{choosenServerPackage.discount}% {t("DiscountMsg")}</div>
                            </div>
                          ) : (
                            ''
                          )
                        }
                       <h3 className="card-header price p-2">
                         {t("Price")}
                       </h3>
                        <div className="d-block text-center price-container">
                          <div className="row">
                            { 
                              choosenServerPackage.discount !== 0 ? (
                                <>
                                  <h2 className="price discount">{t('CurrencyFormat', { currency: discount } )}<small> / {t('FirstMonth')}</small></h2>
                                  <h4 className="price"><s>{t('CurrencyFormat', { currency: choosenServerPackage.price ? choosenServerPackage.price.toFixed(2) : 0 })}</s><small> / {t('AMonth')}</small></h4>
                                </>
                              ) : (
                                <h2 className="price">{t('CurrencyFormat', { currency: choosenServerPackage.price ? choosenServerPackage.price.toFixed(2) : 0 })}<small> / {t('AMonth')}</small></h2>
                              )
                            }
                          </div>
                          <div className="row">
                            { stock === 0 ? <p className="red">{t("ThisProductIsSoldOut")}!</p> : <p><b className={badgeClass}>{ stock }</b> { stock === 1 ? t("StockLeft") : t("StockLeft_Plural") }</p> }
                          </div>

                          { 
                            stock !== 0 ?
                              <Link to={`${process.env.REACT_APP_BASE_URL}/login`} state={{ from: { pathname : location.pathname } }} className="btn btn-success">{t('LoginToPay')} !</Link> 
                            : 
                              ''
                          }
                        </div>
                      </div>
                    </>
                  )
                }

                  </MDBCol>

                </MDBRow>

              </div>
            </div>
          </div>

        </div>

      <Footer />
    </>
  )
};

export default GameServers;