export const TRANSLATIONS_DK = {
    // Function with plural state, så that we have only one and more than one
    // {t('date_format_two', { date: new Date() })} ={' '}
    // {t('keyWithCount', { count: 1 })}
    // keyWithCount: 'Anne has bought {{count}} book',
    // keyWithCount_plural: 'Anne has bought {{count}} books'

    /**  
     * 
     * Site Settings
     * 
     */
    DateFormat: '{{date, DD.MM.YYYY}}',
    CurrencyFormat: '{{currency}} kr',

    JoinOurDiscord: 'Tilmeld dig Discord',
    JoinOurFacebook: 'Tilmeld dig Facebook',
    SiteSettings: 'Side Indstillinger',
    LoadingPayment: 'Forbinder til PayPal...',
    LoadingServers: 'Henter servere...',
    LoadingContent: 'Henter indhold...',
    Loading: 'Henter siden...',

    /**  
     * 
     * Site Language
     * 
     */
    Yes: 'Ja',
    No: 'Nej',
    You: 'Dig',
    From: 'Fra',
    Profile: 'Profil',
    Payments: 'Betalinger',
    Settings: 'Indstillinger',
    Support: 'Support',
    Logout: 'Log ud',
    Login: 'Log Ind',
    LoginToPay: 'Log ind for at købe',
    AvailableGames: 'Tilgængelige Spil',
    ConfigurationServer: 'Server Konfiguration',
    ConfigurationOptions: 'Konfigurations Muligheder',
    Configuration: 'Konfiguration',
    ClientArea: 'Bruger Portal',
    GameServers: 'Servere',
    ServerName: 'Server navn',
    ChooseAServerName: 'Vælg et server navn',
    ChooseGameType: 'Vælg spil type',
    ChooseServerType: 'Vælg en spil type',
    GameType: 'Mulige Spil Typer',
    AllServers: 'Alle servere',
    ServerStatus: 'Server Status',
    OrderNow: 'Bestil nu',
    ServersFrom: 'Servere fra',
    ServerSettings: 'Server Opsætning',
    AMonth: 'Måned',
    MonthlySubscription: 'Automatisk fornyelse hver måned',
    FirstMonth: 'Første Måned',
    Invoice: 'Kvittering',
    CreditBefore: 'Konto',
    CreditLeft: 'Konto efter køb',
    Account: 'Konto',
    ChargeAccount: 'Ikke nok kredit',
    ChargeHere: 'Påfyld her',
    ChargeCreditHere: 'Påfyld din konto',
    ChargeCreditHereMsg: 'Her kan du optanke din konto.',
    PaymentMethods: 'Betalings muligheder',
    ChargeAmount: 'Påfyld beløb',
    Credit: 'Nuværende konto',
    StockLeft: 'Server tilbage',
    StockLeft_Plural: 'Servere tilbage',
    ForgotMsg: 'Har du glemt din adgangskode ?',
    ForgotBtn: 'Send gendannelses link',
    DoesNotExist: 'Ops, findes ikke!',
    DoesNotExistMsg: 'Det produkt du leder efter findes ikke, eller eksistere desværre ikke længere. Vælg menu punktet \'Spille Servere, her finder du en liste over alle vores produkter.',
    DiscountMsg: 'Tilbud',
    Phone: 'Telefon',
    Timezone: 'Tidszone',
    Updates: 'Opdateringer',
    UserMenu: 'Bruger Menu',
    ServerPanel: 'Server panel',
    Unothorized: 'Uautoriseret adgang',
    Amount: 'Antal',
    Date: 'Dato',
    Status: 'Status',
    CreateOne: 'Opret en her',
    CreateTicket: 'Opret ticket',
    CreateSupportTicket: 'Opret Support Ticket',
    DifferentGames: 'Forskellige Spil',
    HostedServers: 'Spil Spillet',
    HappyCustomers: 'Spillere Oprettet',
    StartYourServerNow: 'Start din server nu',
    ItemsPrPage: 'Pr Side',

    Total: 'Total',
    Tax: 'Moms',
    Price: 'Pris',
    Payment: 'Betaling',
    BuyServer: 'Køb Server',
    PayHere: 'Tilføj mere tid her',

    DDoSProtection: 'DDoS Beskyttelse',
    CPUUsed: 'AMD Ryzen 5 3600',
    OneClickSetup: 'Hurtig Opsætning',
    InstantDelivery: 'Øjeblikkelig Levering',
    Packages: 'Pakker',

    FAQ: 'F.A.Q',
    FrequentlyAskedQuestions: 'Ofte Stillede Spørgsmål',
    TermsOfService: 'Servicevilkår',
    PrivacyPolicy: 'Privatlivs Politik',
    UsefulLinks: 'Nyttige Links',
    SiteDescription: 'Vi er et lille hosting firma, der levere lynende hurtige spille servere, til dig eller dit fællesskab. Bygget af gamere, til gamere.',

    Home: 'Forside',
    Overview: 'Oversigt',

    SignInWidth: 'Log ind med',
    SignUpWidth: 'Opret med',
    Or: 'eller',

    Email: 'Email',
    Username: 'Brugernavn',
    Password: 'Adgangskode',
    NewPassword: 'Ny Adgangskode',
    RepeatPassword: 'Gentag Adgangskode',
    ChangePassword: 'Ændre adgangskoden',
    ChangeYourPassword: 'Ændring af adgangskode',
    RememberMe: 'Husk mig',
    ForgotPassword: 'Glemt adgangskode',
    Register: 'Registrer',
    RegisterHere: 'Registrer her',
    NotAMember: 'Ikke medlem',
    SignIn: 'Log ind',

    AgreeTermsStart: 'Jeg har læst og accepteret jeres',
    AgreeTermsEnd: 'Og ',

    EmailNote1: '3 til 30 tegn.',
    EmailNote2: 'Bogstaver, tal, understreg og bindestreg er tilladt',
    PasswordNote1: '8 til 24 tegn',
    PasswordNote2: 'Skal indeholde store og små bogstaver, tal og speciel tegn',
    PasswordNote3: 'Tilladte speciel tegn',
    RepeatPasswordNote: 'Denne adgangskode skal matche den først angivede',

    ChooseAPackage: 'Vælg et spil',
    ChooseACountry: 'Vælg et land',
    Country: 'Land',

    DeployedIn: 'Serveren vil være tilgængelig indenfor ca',

    CPUUsage: 'CPU Forbrug',
    RAMUsage: 'RAM Forbrug',
    DiskUsage: 'Disk Forbrug',

    Validated: 'Konto er valideret',
    DiscordValidation: 'Validering af discord konto',
    UpdateSettings: 'Opdater indstillinger',
    DiscordUsername: 'Discord brugernavn',

    NoWikipediaSelected: 'Der er ikke valgt nogen wikipedia endnu, vælg en for at komme igang',
    SelectWikipedia: 'Vælg en wikipedia',
    Wiki: 'Wiki',
    Wikipedia: 'Wikipedia',
    WikipediaPage: 'Server opsætning guides',
    Topic: 'Emne',
    Anwser: 'Svar',
    Created: 'Oprettet',
    Message: 'Besked',
    Messages: 'Beskeder',
    MessageSent: 'Beskeden blev sendt',
    Tickets: 'Tickets',
    SendTicket: 'Send besked',
    ViewSupportTicket: 'Support Ticket',
    ViewSupportTickets: 'Se Support Tickets',
    WhatProblems: 'Beskriv hvilke problemer du oplever',

    RenewConfig: 'Fornyelses Konfiguration',
    RenewServer: 'Forny Server',

    SERVERPAYMENT: 'Server Betaling',
    ACCOUNTCHARGE: 'Konto Påfyldning',

    EasyToUse: 'Nemt at bruge',
    EasyToUseTitle: 'Brug din tid på at spille',
    EasyToUseText: 'Hos os har du mulighed for nemt selv at ændre din opsætning, og med vores utrolige brugervenlige kontrol panel, er det en leg at styre din server. Du kan nemt lave automatiserede jobs, ændre indstillinger eller starte og stoppe din server med kun få klik. - Du har derfor mulighed for at fokusere på det der er vigtigst, nemlig at game!',
    AddYourFriends: 'Flere brugere',
    AddYourFriendsTitle: 'Få fjælp af dine venner',
    AddYourFriendsText: 'Tilføj selv flere brugere som du kan tildele forskellige roller. Tilføj venner, udviklere eller dine VIP medlemmer, og giv dem mulighed for at styre dele af din server som kun du udvælger. Dette giver dig mulighed for at få hjælp eller være fælles om én server, men husk kun at tilføje personer som du stoler på!',
    EasyFileManager: 'Nemt at ændre filer',
    EasyFileManagerTitle: 'Ændre dine filer nemt og hurtigt',
    EasyFileManagerText: 'Du kan tilføje, slette eller redigere dine server filer direkte i kontrol panelet. Du slipper derfor for at bruge besværlige SFTP programmer, hvis du bare lige hurtigt vil ændre i din servers konfiguration. Hvis du derimod ønsker at bruge SFTP, så har du selvfølgelig også fuld mulighed for dette.',
    WhyChooseUs: 'Hvorfor Os',
    SimplyBecause: 'Simpelthen fordi',

    Next: 'Frem',
    Previous: 'Tilbage',

    /**  
     * 
     * Banners
     * 
     */
    Banner1Title: 'Skynd dig! Og oplev dit livs, måske bedste gaming oplevelse',
    Banner1Desc: 'Det her er din eksklusive chance for at få din helt egen server at spille på! Oplev high-end udstyr og support med vores gaming servere. Men skynd dig – Mens der stadigvæk er nogle ledige!',
    Banner2Title: 'Skynd dig! Og oplev dit livs, måske bedste gaming oplevelse',
    Banner2Desc: 'Det her er din eksklusive chance for at få din helt egen server at spille på! Oplev high-end udstyr og support med vores gaming servere. Men skynd dig – Mens der stadigvæk er nogle ledige!',
    Banner3Title: 'Skynd dig! Og oplev dit livs, måske bedste gaming oplevelse',
    Banner3Desc: 'Det her er din eksklusive chance for at få din helt egen server at spille på! Oplev high-end udstyr og support med vores gaming servere. Men skynd dig – Mens der stadigvæk er nogle ledige!',


    /**  
     * 
     * Packages
     * 
     */
    GBNetwork: '{{ networkspeed }} GB/s netværks opkobling',
    CPUCount: '{{ cpucount }} vCPU op til {{ clockspeed }} Ghz',
    Slots: '{{ slots }} spillere',
    Ticks: '{{ ticks }} Tick',
    GBRAM: '{{ ram }} GB RAM',
    GBDisk: '{{ disk }} GB NVMe SSD',

    DemoHere: 'se demo her',

    LiveMapAvailable: 'Live kort',
    MinecraftGameDescription: 'Minecraft er et overlevelsesspil, hvor der gælder om at overleve med de ressourcer, man har til rådighed. Når man starter et nyt spil, bliver der genereret en ny verden, med alle råstoffer, som findes i Minecraft. Som spiller går det helt simpelt ud på at finde råstoffer, crafte dem om til værktøjer, maskiner og andet, og på den måde gøre det lettere at overleve.',
    MinecraftPackage1Title: 'Glas',
    MinecraftPackage1: 'Til udvikler eller bungeecord server',
    MinecraftPackage2Title: 'Græs',
    MinecraftPackage2: 'Perfekt til en minecraft survival server',
    MinecraftPackage3Title: 'Rødsten',
    MinecraftPackage3: 'Det perfekte valg til minecraft',
    MinecraftPackage4Title: 'Diamand',
    MinecraftPackage4: 'Mere kraft, mere hastighed',
    MinecraftPackage5Title: 'Obsidian',
    MinecraftPackage5: 'Til elite minecraft servers',

    CSGOGameDescription: 'Counter-Strike er en serie af klassiske skydespil, hvor man spiller på to hold. I Counter-Strike: Global Offensive, også kendt som CS:GO eller bare CS, spiller man på hold, hvor terrorister og anti-terrorister, kæmper imod hinanden.',
    CSGOPackage1Title: 'Mini',
    CSGOPackage1: 'If you or your team just wanna play',
    CSGOPackage2Title: 'Small',
    CSGOPackage2: 'If you want to host a custom server',
    CSGOPackage3Title: 'Medium',
    CSGOPackage3: 'For different gamemodes',
    CSGOPackage4Title: 'Large',
    CSGOPackage4: 'Warcraft 3 with db setup',
    CSGOPackage5Title: 'Extra Large',
    CSGOPackage5: 'For those who want the fastest',

    ARKGameDescription: 'Strandet på bredden af en mystisk ø, her skal du lære at overleve. Brug din snedighed til at dræbe eller tæmme oldtids skabninger som dominerer landet, eller røv andre spillere for at overleve, dominere ... eller flygte!',
    ARKPackage1Title: 'Wood',
    ARKPackage1: 'Can only run the basics of ARK',
    ARKPackage2Title: 'Stone',
    ARKPackage2: 'Still basic, but with a little more power',
    ARKPackage3Title: 'Metal',
    ARKPackage3: 'If you want to make a modded server',
    ARKPackage4Title: 'Obsidian',
    ARKPackage4: 'For big maps and lots of build',
    ARKPackage5Title: 'Element',
    ARKPackage5: 'For the extreme ark user',

    VRGameDescription: 'Vågn op som en vampyr. Gå på jagt efter blod i nærliggende bebyggelser for at genvinde din styrke og undgå den brændende sol for at overleve. Opbyg dit slot og udforsk en evigt ændrende verden fuld af mystik. Få allierede online og erobre de levendes land.',
    VRPackage1Title: 'Mini',
    VRPackage1: 'Can only run the basics of ARK',
    VRPackage2Title: 'Small',
    VRPackage2: 'Still basic, but with a little more power',
    VRPackage3Title: 'Medium',
    VRPackage3: 'If you want to make a modded server',
    VRPackage4Title: 'Large',
    VRPackage4: 'For big maps and lots of build',

    RustGameDescription: 'The only aim in Rust is to survive. Everything wants you to die - the island\'s wildlife and other inhabitants, the environment, other survivors. Do whatever it takes to last another night.',
    RustPackage1Title: 'Mini',
    RustPackage1: 'Can only run the basics of Rust',
    RustPackage2Title: 'Small',
    RustPackage2: 'Still basic, but with a little more power',
    RustPackage3Title: 'Medium',
    RustPackage3: 'If you want to make a modded server',
    RustPackage4Title: 'Large',
    RustPackage4: 'For big maps and lots of builds',

    MordhauGameDescription: 'Mordhau is a medieval first & third person multiplayer slasher. Enter a hectic battlefield of up to 64 players as a mercenary in a fictional, but realistic world, where you will get to experience the brutal and satisfying melee combat that will have you always coming back for more.',
    MordhauPackage1Title: 'Mini',
    MordhauPackage1: 'Can only run the basics of Mordhau',
    MordhauPackage2Title: 'Small',
    MordhauPackage2: 'Still basic, but with a little more power',
    MordhauPackage3Title: 'Medium',
    MordhauPackage3: 'If you want to make a modded server',
    MordhauPackage4Title: 'Large',
    MordhauPackage4: 'For big maps and lots of builds',
    
    /**  
     * 
     * TERMS AND SERVICE
     * 
     */
    Terms1Title: 'Ordering',
    Terms1Ordering1: 'The customer is not obliged to make the payment after placing an order.',
    Terms1Ordering2: 'The customer is not committed to anything and can stop paying at any time.',
    Terms1Ordering3: 'Orders will be delivered within the advertised indication on our website, unless there is not enough stock.',
    Terms1Ordering4: 'If the server turns out to be out of stock, it will be delivered within 8 days else a refund will be processed.',
    Terms1Ordering5: '{{ name }} will do its utmost to ensure that there is always enough stock.',

    Terms2Title: 'Game server',
    Terms2Gameserver1: 'If the consumer does not renew the server, {{ name }} is authorized to shut down the server after it has been expired.',
    Terms2Gameserver2: 'If the consumer does not renew the server, {{ name }} is authorized to remove all data of this server, 3 days after the server is expired.',
    Terms2Gameserver3: 'Support with regard to plugins/resources and code is not guaranteed.',
    Terms2Gameserver4: '{{ name }} is not responsible in case of data loss. The customer is responsible for making backups.',

    Terms3Title: 'Support',
    Terms3Support1: 'The customer can open a ticket in our Discord server or send us an email to {{ mail }}, if he has any questions regarding his service.',
    Terms3Support2: 'The official (non-SLA) response time of {{ name }} is 24 hours. But in practice this can of course be a lot faster.',
    Terms3Support3: '{{ name }} only offers support on the services provided by {{ name }}.',
    Terms3Support4: '{{ name }} does not provide support for third-party software.',

    Terms4Title: 'Refounds',
    Terms4Refounds1: 'By placing an order, the customer accepts that {{ name }} does not accept any refund request that isn\'t covered by our refund policy.',
    Terms4Refounds2: 'The customer is entitled to a refund if the services of {{ name }} have an availability of less than 99%, caused by technical issues on our side.',
    Terms4Refounds3: 'Downtime not caused by {{ name }} such as a misconfiguration made by the customer does not count.',
    Terms4Refounds4: 'The customer needs to report a technical issue to the {{ name }} support via the #support channel in our discord server and give {{ name }} enough time to fix the issue.',
    Terms4Refounds5: 'A refund will be denied if the reason for the refund is: \'I don\'t know how it works\'. The customer is responsible for installation and configuration of any programs or plugins in his server.',
    Terms4Refounds6: 'A refund will be processed within 7 business days.',
    Terms4Refounds7: 'Some countries have a legal period in which the customer can terminate their server without giving any reason. The customer then gets his money back. This is called Right of withdrawal (or herroepingsrecht). By placing an order with {{ name }}, you agree to waive this right.',
    Terms4Refounds8: 'The customer may not refund the payment, through payment services such as paypal by submitting a claim. They must always request a refund via the {{ name }} support.',
    Terms4Refounds9: 'If the customer opens a claim with paypal or opens a dispute at the creditcard provider, {{ name }} may immediately stop and delete the services of the customer without warning.',


    Terms5Title: 'Other obligations of {{name}}',
    Terms5OtherObligations1: '{{ name }} is not responsible for the content that the customer is hosting.',
    Terms5OtherObligations2: '{{ name }} will remove illegal content or other illegal applications/scripts that are not permitted by law.',
    Terms5OtherObligations3: '{{ name }} is not responsible for data-loss in any case.',

    Terms6Title: 'Force of the majority',
    Terms6ForceOf1: 'None of the parties is obliged to fulfill any obligation if it is prevented from doing so due to force majeure. Force majeure means in any case: war (danger), riots, strikes, war, fire, water damage, flooding, atmospheric conditions, prolonged power outages, adjustments or maintenance to the telecommunications network and / or electricity network of others, cable breaks, attacks on a network / server including DDoS and DoS, disruptions in the networks important for {{ name }}, disruptions in communicative connections including telecommunication connections or prevention or refusal / prolonged absence of compliance by suppliers on whom {{ name }} depends on the performance of its work is.',

    Terms7Title: 'Credits & Donations',
    Terms7Credits1: '{{ name }} provides a donation panel in which the players of your server can donate money.',
    Terms7Credits2: 'For a 10 kr donation you will receive 110 credits (15kr).',
    Terms7Credits3: 'It\'s not allowed to donate credits to yourself.',
    Terms7Credits4: 'Credits can only be used on the {{ name }} website to renew your server, upgrade your server or to buy a new server.',
    Terms7Credits5: 'Credits cannot be refunded.',

    Terms8Title: 'Changes in terms and services',
    Terms8Changes1: '{{ name }} is allowed to change the terms and services at any time.',
    Terms8Changes2: '{{ name }} has to announce every change in the #announcements channel in it\'s Discord server.',
    

    /**  
     * 
     * INPUT PLACEHOLDERS
     * 
     */
    DiscordValidatePlaceholder: 'Indtast dit discord brugernavn her',


    /**  
     * 
     * SUCCESS MESSAGES
     * 
     */
    SettingsUpdated: 'Indstillingerne blev opdateret',
    TicketHasBeenCreated: 'Din support ticket er blevet oprettet, du vil høre fra os snart',
    YourAccountHasBeenCreated: 'Din bruger er nu blevet oprettet',
    RecoveryLinkSent: 'Gendannelses link sendt',


    /**  
     * 
     * WARNING MESSAGES
     * 
     */
    DeletesIn: 'Vil blive slettet om',
    RenewServerMsg: 'Her kan du ændre serverens indstillinger inden du fornyer den, indstillingerne træder først i kraft når betalingen er gennemført',
    NeedsToBeExactUsername: 'Skal være et gyldigt discord brugernavn for at virke',
    PendingDiscordVerification: 'Sådan! Nu mangler du bare at klikke på ikonet i vores discord kanal',
    OnlyChangeIfYouNeed: 'Udfyld kun, hvis du ønsker dette ændret',
    NoSupportTicketsFound: 'Du har ingen support tickets oprettet',
    TicketIsClosed: 'Denne support ticket er blevet lukket',
    ServerMissingPayment: 'Serveren mangler betaling',
    AreYouSure: 'Er du helt sikker',
    WantToDeleteServer: 'Du er igang med at slette denne server, alt data vil blive slettet og dit resterende abonnoment vil gå tabt uden refusion. Er du sikker på at du vil udføre dette ?',


    /**  
     * 
     * ERROR MESSAGES
     * 
     */
    WrongEmailOrPassword: 'Forkert brugernavn eller adgangskode',
    SomethingFailed: 'Der skete en fejl, prøv at opdater siden',
    PasswordRecoveryComplete: 'Adgangskoden er nu blevet ændret',
    PasswordNeedsReset: 'Adgangskode ændring mangler',
    TooManyFailedLogins: 'For mange mislykkede forsøg, prøv igen om {{ expiresIn }}',
    TheAccountIsNowActivated: 'Kontoen er aktiveret, du kan nu logge ind',
    AccountNeedsActivation: 'Kontoen mangler aktivering',
    CouldNotCheckOutFromPayPal: 'Kunne ikke færdiggøre betalingen fra paypal',
    CouldNotConnectToPayPal: 'Kunne ikke få forbindelse til paypal',
    PageNotFound: 'Siden blev ikke fundet',
    PageNotFoundMsg: 'Vi beklager, men siden du leder efter, eksistere desværre ikke mere',
    NotEnoughToProceed: 'Ikke nok til at fortsætte, prøv igen',
    ServerSuspended: 'Denne server er blevet låst',
    NoServersFound: 'Du har ingen servere tilknyttet',
    ContactSupport: 'Kontakt support',
    AcceptOurAgreement: 'Accepter vores betingelser først',
    PaymentFailed: 'Betalingen fejlede',
    ThisProductIsSoldOut: 'Dette produkt er desværre udsolgt',
    ThisIsNotAnOption: 'Dette er ikke en mulighed',
    NoServerResponse: 'Ingen svar fra serveren',
    PasswordDoesNotMatch: 'Adgangskoderne er ikke ens',
    MissingPassword: 'Mangler adgangskode',
    MissingEmail: 'Mangler email',
    MissingEmailAndPassword: 'Mangler email eller adgangskode',
    EmailAlreadyInUse: 'Email er allerede i brug',
    Unauthorized: 'Ingen adgang',
    ThereWasAnError: 'Der skete en fejl',
    RecoveringFailed: 'Gendannelse fejlede',
    RegistrationFailed: 'Registreringen fejlede',
    LoginFailed: 'Log ind fejlede',
    InvalidEntry: 'Ugyldigt indtastning',
    InvalidToken: 'Ugyldig token, opdater siden',
    RecoverySentToAccount: 'Et gendannelses link er blevet sendt til din email',
    NoPaymentsToShow: 'Der er ingen betalinger at vise'
};