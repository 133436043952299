import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Banner = (vars) => {
  const { t } = useTranslation();
  const bannerType = vars.banner;
  let title,
      content,
      bg;

  switch(bannerType) {
    default:
    case 1:
      title = `${t('Banner1Title')}!`;
      content = `${t('Banner1Desc')}`;
      bg = `csbg`;
      break;
    case 2:
      title = `${t('Banner2Title')}!`;
      content = `${t('Banner2Desc')}`;
      bg = `rust`;
      break;
    case 3:
      title = `${t('Banner3Title')}!`;
      content = `${t('Banner3Desc')}`;
      bg = `ark`;
      break;
  }

  return (
    <>
      <div className="container" id="banner">
        <Row id="news">
          <Col className={`col ${bg}`} xs={vars.xs} sm={vars.sm} md={vars.md} lg={vars.lg}>
            <div className="bdr"></div>
              <Col xs={6} sm={6} md={6} lg={6}>
                <h3 className="mb-3">{title}</h3>
                <p>{content}</p>
                <a href="/overview" className="btn btn-primary">{t('StartYourServerNow')}! <div className="dot"></div></a>
              </Col>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Banner;