// import { useTranslation } from 'react-i18next';
// import { useEffect } from 'react';
import * as Icon from 'react-bootstrap-icons';

const CommingSoon = () => {
    // const { t } = useTranslation();

      return (
        <>
          <div className="container d-block align-items-center micro">
            <img src={`${process.env.REACT_APP_BASE_URL}/logo.png`} className="clogo w-50" alt="logo" />
            <p className="alert alert-default d-flex justify-content-around mt-3">
              <i><Icon.HourglassSplit className="ms-2 mt-1 me-3 fs-1 text-warning hopOutAndRotate"/></i>
                Bare lige lidt endnu, så er vi klar!<br/> Platformen er desværre stadigvæk under opbygning.
                <a href="https://discord.gg/CzdF799GRn" className="btn btn-purple ms-4 hopUp">
                  <Icon.Discord className="fs-5 text-white"/>
                </a>
            </p>
          </div>
        </>
      );
};

export default CommingSoon;